export const en = {
  "translation": {
    "pepa_buendia_natacion": "Pepa Buendía Natación",
    "clases": "Clases",
    "contactanos": "Contáctanos",
    "inscripcion": "Inscripción",
    "home_hero_title": "Clases personalizadas de natación",
    "home_hero_subtitle": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    "mas_informacion": "Más Información",
    "home_motto": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
    "home_intro": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    "info_del_curso": "Información de las clases",
    "info_del_curso_item_1": "Lorem ipsum dolor sit amet.",
    "info_del_curso_item_2": "Lorem ipsum dolor sit amet.",
    "info_del_curso_item_3": "Lorem ipsum dolor sit amet.",
    "info_del_curso_item_4": "Lorem ipsum dolor sit amet.",
    "info_del_curso_item_5": "Lorem ipsum dolor sit amet.",
    "info_del_curso_item_6": "Lorem ipsum dolor sit amet.",
    "sobre_mi": "Sobre mí",
    "sobre_mi_texto": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    "nademos": "Nademos",
    "en_construccion": "Esta página está en construcción!",
    "clases_hero_title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    "intermediate_course_title": "Curso nivel intermedio",
    "intermediate_course_item_1": "Nivel: B1/B2.1",
    "intermediate_course_item_2": "Sesiones: Miércoles de 19:00 a 20:30 hrs CET (European time)",
    "intermediate_course_item_3": "Inicio: 12 de Mayo de 2021",
    "intermediate_course_item_4": "Fin: 30 de Junio de 2021",
    "intermediate_course_item_5": "8 sesiones de 90 minutos",
    "intermediate_course_item_6": "Modalidad: via Zoom",
    "intermediate_course_item_7": "Materiales incluidos",
    "intermediate_course_item_8": "Máximo 3 participantes",
    "advanced_course_title": "Curso nivel avanzado",
    "advanced_course_item_1": "Nivel: B2.2/C1",
    "advanced_course_item_2": "Sesiones: Martes de 19:00 a 20:30 hrs CET (European time)",
    "advanced_course_item_3": "Inicio: 11 de Mayo de 2021",
    "advanced_course_item_4": "Fin: 29 de Junio de 2021",
    "advanced_course_item_5": "8 sesiones de 90 minutos",
    "advanced_course_item_6": "Modalidad: via Zoom",
    "advanced_course_item_7": "Materiales incluidos",
    "advanced_course_item_8": "Máximo 3 participantes",
    "course_info_title": "Descripción de las clases",
    "course_info_text": "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.",
    "course_objective_title": "Objetivos de las clases",
    "course_objective_text": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur",
    "prices": "Precios",
    "students": "Estudiantes",
    "student_price": "€60",
    "student_price_description": "Precio exclusivo para estudiantes con credencial vigente o matriculados en alguna universidad.",
    "general_public": "Público general",
    "general_public_price": "€90",
    "general_public_price_description": "Abierto para adultos mayores a 18 años.",
    "name": "Nombre",
    "lastname": "Apellido(s)",
    "email": "Email",
    "message": "Mensaje",
    "send": "Enviar",
    "contact_us_title": "Contáctanos",
    "contact_us_text": "¿Tienes dudas? no dudes en escribirme si necesitas mas información.",
    "contact_form_sent": "Gracias por tu mensaje. Muy pronto estaré en contacto contigo.",
    "contact_form_sent_error": "Ha ocurrido un error. Por favor intentalo más tarde.",
    "inscription": "Inscripción",
    "inscription_form_title": "Para inscribirte, llena por favor el siguiente formulario",
    "inscription_spanish_learning_question": "¿En dónde has aprendido antes español?",
    "inscription_are_you_student_question": "¿Eres estudiante?",
    "inscription_course_question": "Curso de interes",
    "yes": "Sí",
    "no": "No",
    "intermediate_level": "Nivel intermedio",
    "advanced_level": "Nivel avanzado",
    "nosotros": "Nuestro equipo"
  }
}